import React, {CSSProperties, ReactNode} from 'react';
import './App.css';

import { Container } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

import { Youtube, H3, H2, Caption, VSpacer, useWindowSize, useIsSp } from "./Utils"
import { isJSDocNullableType } from 'typescript';

function MainTitle() {
  const isSp = useIsSp()

  const style: CSSProperties = {
    fontFamily: "TitleFont",
    fontWeight: 900,
    fontSize: isSp ? 42 : 80,
    lineHeight: "1em",
  }

  return <Container style={style}>
    <div>TOKYO 2020</div>
    <div>IMMERSIVE TELEPRESENCE</div>

    <div style={{fontSize: isSp ? 16 : 40}}>-Real-time remote marathon cheering-</div>
  </Container>
}

function MainVideo() {
  return <Container>
    <Youtube id={"MgsdmMue2ig"}></Youtube>
  </Container>
}


function Title(props: {children: ReactNode}) {
  return <div style={{
    fontFamily: "TitleFont",
    fontWeight: 900,
    fontSize: 40,
  }}>{props.children}</div>
}

function Text(props: {children: ReactNode}) {
  return <div style={{
    // fontFamily: "TitleFont",
    fontSize: 16,
    color: "white",
    lineHeight: "1.4em",
  }}>{props.children}</div>
}

function Background() {
  return <Container>
    <Title>BACKGROUND</Title>
    <VSpacer height={8}/>
    <Text>
      NTT collaborated with the TOKYO 2020 Organising Committee to create an ultra-real, innovative spectating style for the badminton competition.
    </Text>
  </Container>;
}

function Challenge() {
  return <Container>
    <Title>CHALLENGE</Title>
    <VSpacer height={8}/>
    <Text>
      NTT endeavored to leverage Ultra-realistic Communication Technology to convey realism and unity, affording spectators unable to attend the badminton competition a sense of being on-site with the athletes. Images of the athletes and shuttlecocks are extracted from 8K video taken on-site, transmitted using high-speed communications, and projected as holographic images on a full-scale remote pseudo-court. NTT’s technology enables stable, finely differentiated transmission of images, even the occasionally overlapping figures of athletes in constant motion and the contours of small and rapidly moving shuttlecocks, which are so difficult to capture.
    </Text>
  </Container>;
}

function Result() {
  return <Container>
    <Title>RESULT</Title>
    <VSpacer height={8}/>
    <Text>
      The project successfully created a shared presence, stirring emotions in a way not possible in TV broadcasts. The results were praised by Olympic and Paralympic athletes and affiliated parties and were broadly covered by overseas media outlets such as NBCU, the Wall Street Journal, and Bloomberg, representing an advertising cost conversion of 420 million yen (US$3.4 million). Application of this technology transcends sporting events, with potential for usage in the performing arts and other areas.
    </Text>

    <VSpacer height={16} />

    <img style={{width: "100%"}} src="images/main-visual@4x-50.jpg" />

    <img style={{width: "33.33%"}} src="images/result-01@8x-50.jpg" />
    <img style={{width: "33.33%"}} src="images/result-02@8x-50.jpg" />
    <img style={{width: "33.33%"}} src="images/result-03@8x-50.jpg" />

  </Container>;
}

function Technology() {
  return <Container>
    <Title>TECHNOLOGY</Title>
    <VSpacer height={8}/>
    <Text>
      Special studios featuring green screens and other equipment are normally required for extraction of subjects from images. However, that can’t be used when dealing with sports. For the Tokyo 2020 badminton competition, NTT developed a new real-time subject extraction technology allowing stable extraction of athlete and shuttlecock images in real time, transmitting those videos to a remote venue while overcoming environmental challenges such as changing backgrounds, overlapping athletes, and variations in lighting. Moreover, although holographic video equipment had previously been limited to monolayer displays, the current project succeeded in expanding that technology to enable multi-layer displays of badminton players on both sides of the net, giving individual three-dimensional expression to both foreground and background players as a global first.
    </Text>

    <VSpacer height={32}/>

    <img style={{width: "100%"}} src="images/tech-01@8x-50.jpg" />
    <VSpacer height={32}/>
    <img style={{width: "calc(50% - 1px)", border: "1px solid white", marginRight: 8}} src="images/tech-02@8x-50.jpg" />
    <img style={{width: "calc(44.5% - 1px"}} src="images/tech-03@8x-50.jpg" />


  </Container>;
}

function App() {
  const size = useWindowSize()

  const style: CSSProperties = {
    backgroundColor: "#555454",
    minHeight: size.height,
    color: 'white',
    overflow: 'hidden',
  }

  return (
    <div style={style}>
      <VSpacer height={60} />
      <MainTitle></MainTitle>
      <VSpacer height={20} />
      <MainVideo></MainVideo>

      <VSpacer height={20} />

      <Background />
      <VSpacer height={40} />

      <Challenge />
      <VSpacer height={40} />

      <Result />
      <VSpacer height={40} />

      <Technology />

      <VSpacer height={60} />
    </div>
  );
}

export default App;
